import React, { useCallback, useMemo } from 'react';

import { ExtandedFormContext, FormStructure } from '@europrocurement/flexy-form';

import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';

import { UseFormReturn } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useModal, type HeaderSwitch } from '@europrocurement/flexy-components';
import { useNavigate, useParams } from 'react-router';
import { Box, CircularProgress, Stack } from '@mui/material';
import TooltipedIcon from '@b2d/pages/Offres/components/TooltipedIcon';
import ICONS from '@b2d/icons';
import { navigateToMultiAnnonces } from '@b2d/utils/navigationHelper';
import useUpdateDiscountRate from '@b2d/pages/Achats/hooks/useUpdateDiscountRate';
import {
    AppDispatch,
    dossierDataSourcesThunks,
    getFournisseurAc,
    RootStateType,
} from '@b2d/redux/RootStore';
import {
    Dossier,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    DossierFormalite,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    FactureAchat,
    FactureAchatNote,
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
    Fournisseur,
    MediaObject,
    RubriqueFacturation,
} from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { multiAnnonceModalMessages } from '@b2d/pages/Achats/constants/wording/modals';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { dossierFormaliteDataSourcesThunks } from '@b2d/redux/subReducers/FormalityReducer';
import { Nullable } from '@b2d/pages/Achats/constants/types';
import { ACTIONS, FactureFormReducerType } from '@b2d/redux/FactureFormReducer';
import useShowMessage from '@b2d/hooks/useShowMessage';
import useHeaderSectionStructure from './useHeaderSectionStructure';
import useTotalsSectionStructure from './useTotalsSectionStructure';
import useFolioFormalitySectionStructure from './useFolioFormalitySectionStructure';
import useFolioPublicationSectionStructure from './useFolioPublicationSectionStructure';
import usePublisherSectionStructure from './usePublisherSectionStructure';
import useProductsSectionStructure from './useProductsSectionStructure';
import { ModeProps, SectionHeadControlProps } from '../types';
import useSwitchLockValues from '../../../../hooks/useSwitchLockValues';
import { FactureFormObject, RSFRubFac } from '../../types';
import StatusList from '../../../fragments/StatusList';
import HeaderButton from '../../../fragments/HeaderButton';
import { NotesMediaObject } from '../../../widgets/NotesMediaObject';
import NoteDossier from '../../../notes/NoteDossier';
import { NotesFacturePending } from '../../../widgets/NotesFacturePending';
import ConfirmationModalContent from '../../../modals/ConfirmationModalContent';
import { useResetAllForm } from '../../functions/generalFunctions';
import SearchDossierFormaliteModalContent from '../../../modals/SearchDossierFormaliteModalContent';
import SearchDossierModalContent from '../../../modals/SearchDossierModalContent';
import { updateFormTotaux } from '../../functions/calculsProduits';
import NoteModalFormFournisseur from '../../../informationWidget/NoteModalFormFournisseur';
import useHandleAdornment from '../utils/useHandleAdornment';

export type UseHandleSectionsProps = {
    formContext: UseFormReturn<Partial<FactureFormObject>, unknown, undefined>;
    state: FactureFormReducerType;
    stateNotes: {
        get: FactureAchatNote[];
        set: (notes: FactureAchatNote[]) => void;
    };
};

const useHandleSections = ({ formContext, state, stateNotes }: UseHandleSectionsProps) => {
    /** ************************** */
    /* DÉBUT - DataSources - DÉBUT */
    /** ************************** */

    const achatsFromStatementDataSource: DataSource<FactureAchat> = useSelector(
        (s: RootStateType) => s.achats.factureachat[FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME],
        _.isEqual,
    );

    const dossiersDataSource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.autocompleteDossiers,
        _.isEqual,
    );

    const formalitesDataSource: DataSource<DossierFormalite> = useSelector(
        (s: RootStateType) => s.formalites.dosform.autocompleteDossiersFormalites,
        _.isEqual,
    );

    const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
        _.isEqual,
    );

    const invoiceCategoriesDataSource: DataSource<RubriqueFacturation> = useSelector(
        (s: RootStateType) => s.dossiers.rubfac.main,
        _.isEqual,
    );

    /** ********************** */
    /* FIN - DataSources - FIN */
    /** ********************** */

    /** ***************************** */
    /* DÉBUT - Selected Items - DÉBUT */
    /** ***************************** */

    const mediaObjectSelected: MediaObject | undefined = useSelector(
        (s: RootStateType) => s.achats.mediaobject.main.selected,
        _.isEqual,
    );

    const dossierSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
        _.isEqual,
    );

    const fournisseurSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures.selected,
        _.isEqual,
    );

    /** ************************* */
    /* FIN - Selected Items - FIN */
    /** ************************* */

    /** ********************************** */
    /* DÉBUT - Global Custom Hooks - DÉBUT */
    /** ********************************** */

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const { mediaobjectid, statementid } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const showMessage = useShowMessage();

    const { modalActions } = useModal();
    const { getSwitchLockValues, stateSwitchLockValues } = useSwitchLockValues();
    const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });
    const { modalDiscountRate, stateDiscountRate } = useUpdateDiscountRate({
        formContext,
        stateSwitchLockValues,
        fournisseur: fournisseurSelected,
        overwriteAfterUpdate: () => {
            updateTotals({
                reasonToTriggerConfirmationModal: !stateSwitchLockValues.totals.value,
            });
        },
    });

    const resetAllForm = useResetAllForm();

    /** ****************************** */
    /* FIN - Global Custom Hooks - FIN */
    /** ****************************** */

    /** ***************************** */
    /* DÉBUT - Header Section - DÉBUT */
    /** ***************************** */

    const headerMode = useMemo<ModeProps>(() => ({ type: 'register', nature: 'simple' }), []);

    const headerChildrenList = useMemo<SectionHeadControlProps>(
        () => ({
            component: (
                <>
                    <StatusList itemToWatch={mediaObjectSelected} />
                    <HeaderButton
                        content="Notes"
                        onClick={() => {
                            modalActions.call(
                                <Box>
                                    {mediaObjectSelected ? (
                                        <NotesMediaObject title="Notes de traitement du PDF" />
                                    ) : (
                                        <CircularProgress />
                                    )}
                                    <NoteDossier dossierSelected={dossierSelected} />
                                    <NotesFacturePending
                                        title={
                                            <Stack
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                gap={1}
                                            >
                                                Notes de la facture
                                                <TooltipedIcon
                                                    icon={ICONS.actions.help}
                                                    tooltip="Enregistrée(s) une fois la saisie validée."
                                                />
                                            </Stack>
                                        }
                                        notes={stateNotes.get}
                                        notesChange={stateNotes.set}
                                    />
                                </Box>,
                                true,
                                true,
                            );
                        }}
                    />
                    <HeaderButton
                        content="Facture multi-annonces"
                        onClick={() => {
                            modalActions.call(
                                <ConfirmationModalContent
                                    messages={multiAnnonceModalMessages}
                                    actionOnValidation={() => {
                                        if (mediaObjectSelected) {
                                            resetAllForm(formContext);
                                            navigateToMultiAnnonces(
                                                showMessage,
                                                navigate,
                                                mediaobjectid
                                                    ? Number.parseInt(mediaobjectid, 10)
                                                    : undefined,
                                                statementid
                                                    ? Number.parseInt(statementid, 10)
                                                    : undefined,
                                            );
                                        } else {
                                            enqueueSnackbar('PDF manquant !', {
                                                variant: 'error',
                                            });
                                        }
                                    }}
                                />,
                            );
                        }}
                    />
                </>
            ),
            side: 'right',
        }),
        [
            dossierSelected,
            enqueueSnackbar,
            formContext,
            mediaObjectSelected,
            mediaobjectid,
            modalActions,
            navigate,
            resetAllForm,
            showMessage,
            stateNotes,
            statementid,
        ],
    );

    const handleUpdateAvoir = useCallback(() => {
        updateTotals({
            reasonToTriggerConfirmationModal: !stateSwitchLockValues.totals.value,
        });
    }, [stateSwitchLockValues, updateTotals]);

    const { headerSectionStructure, stateHeaderSectionStructure } = useHeaderSectionStructure({
        formContext,
        handleUpdateAvoir,
        mode: headerMode,
        headerChildren: headerChildrenList,
        isInterne,
        mediaObject: mediaObjectSelected,
        achatsFromStatementDataSource,
    });

    /** ************************* */
    /* FIN - Header Section - FIN */
    /** ************************* */

    /** **************************** */
    /* DÉBUT - Folio Section - DÉBUT */
    /** **************************** */

    const modeFolioFormality = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'simple',
        }),
        [],
    );

    const handleClickModalSearchDossierFormalite = useCallback(() => {
        modalActions.call(
            <SearchDossierFormaliteModalContent
                onCloseModal={(dossier: Nullable<DossierFormalite>) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_FORMALITES_SLICE_NAME}/set${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                }}
            />,
            true,
            true,
        );
    }, [dispatch, modalActions]);

    const { folioFormalitySectionStructure } = useFolioFormalitySectionStructure({
        dataSource: formalitesDataSource,
        fetchData: dossierFormaliteDataSourcesThunks.autocompleteDossiersFormalites.getData,
        mode: modeFolioFormality,
        state,
        onClickModal: handleClickModalSearchDossierFormalite,
    });

    const { searchByNumeroPresta } = dossierDataSourcesThunks.autocompleteDossiers.data;

    const handleClickModalSearchDossierAnnonce = useCallback(() => {
        modalActions.call(
            <SearchDossierModalContent
                onCloseModal={(dossier: Nullable<Dossier>) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_SLICE_NAME}/set${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                }}
            />,
            true,
            true,
        );
    }, [dispatch, modalActions]);

    const modeFolioPublication = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'simple',
        }),
        [],
    );

    const { folioPublicationStructure } = useFolioPublicationSectionStructure({
        dossiersDataSource,
        dossierFetchData: dossierDataSourcesThunks.autocompleteDossiers.getData,
        mode: modeFolioPublication,
        searchByNumeroPresta,
        state,
        handleClickModalSearchDossierAnnonce,
    });

    /** ************************ */
    /* FIN - Folio Section - FIN */
    /** ************************ */

    /** ******************************** */
    /* DÉBUT - Publisher Section - DÉBUT */
    /** ******************************** */

    const publisherMode = useMemo<ModeProps>(() => ({ type: 'register', nature: 'simple' }), []);

    const publisherSwitchesList = useMemo<Array<HeaderSwitch>>(() => {
        const component = [];

        if (publisherMode.type === 'update') {
            // Display the switch to lock or unlock the publisher section.
            component.push(getSwitchLockValues({ sectionToBlock: 'publisher' }));
        }

        return component;
    }, [getSwitchLockValues, publisherMode.type]);

    const publisherChildrenList = useMemo<Array<SectionHeadControlProps>>(() => {
        const component: Array<SectionHeadControlProps> = [];

        if (
            fournisseurSelected &&
            fournisseurSelected?.notes &&
            fournisseurSelected.notes.length > 0
        ) {
            component.push({
                component: (
                    <NoteModalFormFournisseur
                        noteFournisseur={fournisseurSelected.notes as string}
                    />
                ),
                side: 'left',
            });
        }
        return component;
    }, [fournisseurSelected]);

    /**
     * TODO: find a way to avoid the loop
     */
    const { publisherAdornment } = useHandleAdornment({
        formContext,
        modalDiscountRate,
        fournisseur: fournisseurSelected,
        stateSwitchLockValues,
    });

    const { publisherStructure } = usePublisherSectionStructure({
        dataSource: fournisseursDataSource,
        fetchData: getFournisseurAc,
        defaultInputValue: state.defaultSiren,
        mode: publisherMode,
        headerSwitches: publisherSwitchesList,
        headerChildren: publisherChildrenList,
        adornment: publisherAdornment,
    });

    /** **************************** */
    /* FIN - Publisher Section - FIN */
    /** **************************** */

    /** ******************************* */
    /* DÉBUT - Products Section - DÉBUT */
    /** ******************************* */

    const switchDisplaySells = useMemo<HeaderSwitch>(() => {
        const switchComponent: HeaderSwitch = {
            label: '',
            sx: {
                display: 'none',
            },
        };

        if (state.displaySwitchToSeeSells && isInterne) {
            switchComponent.label = 'Afficher les lignes de vente';
            switchComponent.defaultChecked = state.displaySells;
            switchComponent.onChange = () => {
                dispatch({
                    type: ACTIONS.DISPLAY_SELLS,
                    payload: !state.displaySells,
                });
            };
            switchComponent.checked = state.displaySells;
            switchComponent.sx = {};
        }
        return switchComponent;
    }, [dispatch, state.displaySells, state.displaySwitchToSeeSells, isInterne]);

    const productsSwitchesList = useMemo(() => {
        const component = [];

        if (switchDisplaySells && state.displaySwitchToSeeSells) {
            component.push(switchDisplaySells);
        }

        return component;
    }, [switchDisplaySells, state.displaySwitchToSeeSells]);

    const handleTotalsLock = useCallback(
        (formContextOnChangeInput: ExtandedFormContext) => {
            updateFormTotaux({
                formContext: formContextOnChangeInput,
                reasonToTriggerUpdate: stateSwitchLockValues.totals.value,
            });
        },
        [stateSwitchLockValues],
    );

    /**
     * Invoice Categories without filters,
     * with the RSF category addition if not already includes.
     */
    const invoiceCategoriesForInternalUsers = useMemo(() => {
        // Avoid duplicate RSF category
        const rsfCategoryNotAlreadyApplied = !invoiceCategoriesDataSource.data.find(
            (category) => category.id === 99,
        );

        return [
            ...invoiceCategoriesDataSource.data,
            ...(rsfCategoryNotAlreadyApplied ? [RSFRubFac] : []),
        ];
    }, [invoiceCategoriesDataSource.data]);

    /**
     * Invoice Categories filtered or not depending on user nature (MADA or Internal)
     *
     * RG-Achat-70
     *
     * Do not filtering invoice categories if internal user.
     */
    const invoiceCategories = useMemo(
        () => (isInterne ? invoiceCategoriesForInternalUsers : state.rubFacts),
        [invoiceCategoriesForInternalUsers, isInterne, state.rubFacts],
    );

    const { productsSectionStructure } = useProductsSectionStructure({
        formContext,
        rubFacts: invoiceCategories,
        txTva: state.txTva,
        mode: { type: 'register' },
        headerSwitches: productsSwitchesList,
        hasBlured: stateDiscountRate.hasBlured.value,
        openModal: () => {
            modalDiscountRate();
        },
        produitsVente: state.produitsVente,
        displaySells: isInterne ? state.displaySells : false,
        handleTotalsLock,
        stateSwitchLockValues,
    });

    /** *************************** */
    /* FIN - Products Section - FIN */
    /** *************************** */

    /** ***************************** */
    /* DÉBUT - Totals Section - DÉBUT */
    /** ***************************** */

    const modeTotals = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'simple',
            lock: stateSwitchLockValues.totals.value,
        }),
        [stateSwitchLockValues.totals.value],
    );

    const totalsSwitchesList = useMemo<Array<HeaderSwitch>>(() => {
        const component = [];

        // Display the switch to lock or unlock the header section.
        component.push(
            getSwitchLockValues({
                sectionToBlock: 'totals',
                overwriteAction: () => {
                    if (stateSwitchLockValues.totals.value) {
                        updateTotals({
                            reasonToTriggerConfirmationModal: !stateSwitchLockValues.totals.value,
                        });
                    }
                },
            }),
        );

        return component;
    }, [getSwitchLockValues, stateSwitchLockValues, updateTotals]);

    const { totalsSectionStructure } = useTotalsSectionStructure({
        mode: modeTotals,
        headerSwitches: totalsSwitchesList,
        isMulti: false,
    });

    /** ************************* */
    /* FIN - Totals Section - FIN */
    /** ************************* */

    /**
     * Ordre et affichage des sections de formulaire
     *
     * @returns FormStructure[]
     */
    const factureFormStructure: FormStructure[] = useMemo(() => {
        const structure: FormStructure[] = [];

        const dossierStructure =
            state.defDomain === 3 ? folioFormalitySectionStructure : folioPublicationStructure;

        structure.push(
            ...headerSectionStructure,
            ...dossierStructure,
            ...publisherStructure,
            ...productsSectionStructure,
            ...totalsSectionStructure,
        );

        return structure;
    }, [
        headerSectionStructure,
        folioPublicationStructure,
        folioFormalitySectionStructure,
        publisherStructure,
        productsSectionStructure,
        totalsSectionStructure,
        state.defDomain,
    ]);

    return useMemo(
        () => ({
            factureFormStructure,
            sections: {
                header: headerSectionStructure,
                folio: folioPublicationStructure,
                publisher: publisherStructure,
                products: productsSectionStructure,
                totals: totalsSectionStructure,
            },
            states: {
                header: stateHeaderSectionStructure,
            },
        }),
        [
            factureFormStructure,
            folioPublicationStructure,
            headerSectionStructure,
            productsSectionStructure,
            publisherStructure,
            stateHeaderSectionStructure,
            totalsSectionStructure,
        ],
    );
};
export default useHandleSections;
