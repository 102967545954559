import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import Axios from 'axios';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Grid, Typography } from '@mui/material';

import { footerHeight, TopbarHeight } from '@europrocurement/flexy-components';
import { FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { CustomizerReducerType, MediaObject, setXIdSociete } from '@europrocurement/l2d-domain';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';

import { navigateToNewFacture, navigateToRecapStatement } from '@b2d/utils/navigationHelper';
import { tradErrors } from '@europrocurement/l2d-utils';

import { contactSupport, refreshPage, waitOrSupport } from '@b2d/utils/wording';
import useLoadingStatus from '@b2d/hooks/useLoading';
import SkeletonLoader from '@b2d/pages/Achats/components/SkeletonLoader';
import useShowMessage from '@b2d/hooks/useShowMessage';
import useHandleCleanIOInvoiceForm from '@b2d/hooks/useHandleCleanIOInvoiceForm';
import {
    MediaObjectSelector,
    selectMediaObject,
    factureAchatApi,
    AppDispatch,
    customizerSelector,
    getMediaObjectByViewName,
    getMediaObject,
} from '../../../../../redux/RootStore';

import RegisterSimpleInvoiceForm from '../../../components/forms/simple/RegisterSimpleInvoiceForm';
import { FactureFormObject, FactureAchatCreate } from '../../../components/forms/types';
import { MediaObjectReader } from '../../../components/widgets/MediaObjectReader';
import { useSynchroG3Facture } from '../../../components/forms/functions/dataHooks';
import {
    useBackFromMediaObjectEdit,
    useNavigateToMediaObjectsList,
} from '../../../achatRouterHooks';

const RegisterSimpleInvoiceView: FunctionComponent = function () {
    const { mediaobjectid } = useParams();
    const { xIdSociete } = useSelector(customizerSelector);
    const { enqueueSnackbar } = useSnackbar();
    const synchroG3Facture = useSynchroG3Facture();
    const navigateToListe = useNavigateToMediaObjectsList();
    const showMessage = useShowMessage();
    const { inUseProcess } = useHandleCleanIOInvoiceForm({});

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const mediaObjectDataSource: DataSource<MediaObject> = useSelector(MediaObjectSelector).main;
    const [mediaObjectStatus, setMediaObjectStatus] = useState<EuroprocApiResponseStatus>('idle');

    /**
     * S'il existe un mediaobjectid et que le statut du MediaObject n'est pas "succeeded",
     * alors on modifie sa date de début de traitement à la date d'aujourd'hui
     * et on passe son statut à "succeeded".
     */
    useEffect(() => {
        if (!mediaobjectid) {
            enqueueSnackbar(<Typography>Il n&apos;y a pas de PDF correspondant ! 💥</Typography>);
        } else if (mediaObjectStatus !== 'succeeded') {
            inUseProcess();
            setMediaObjectStatus('succeeded');
        }
    }, [enqueueSnackbar, inUseProcess, mediaObjectStatus, mediaobjectid]);

    /**
     * Select MediaObject corresponding url param mediaobjectid.
     *
     * Should select MediaObject on mount.
     */
    useEffect(() => {
        if (mediaobjectid) {
            dispatch(selectMediaObject({ id: +mediaobjectid }));
        } else {
            enqueueSnackbar(
                `Le PDF n'est peut-être pas le bon, car son identifiant n'a pas été trouvé dans l'url. ${refreshPage} ${contactSupport}`,
                {
                    variant: 'warning',
                },
            );
        }
    }, [dispatch, enqueueSnackbar, mediaobjectid]);

    const mediaObject = useMemo(
        () => mediaObjectDataSource.selected,
        [mediaObjectDataSource.selected],
    );

    /**
     * Si le PDF a un idSociete,
     * alors le définir comme xIdSociete de l'utilisateur
     */
    useEffect(() => {
        if (mediaObject?.idSociete) {
            dispatch(setXIdSociete(mediaObject?.idSociete as CustomizerReducerType['xIdSociete']));
        }
    }, [dispatch, mediaObject?.idSociete]);

    const newFacture: Partial<FactureFormObject> = useMemo(
        () => ({
            produits: [
                {
                    rubriqueFacturation: {
                        code: 'PUB',
                        id: 1,
                    },
                    txtva: {
                        label: 20,
                        value: 20,
                    },
                },
            ],
        }),
        [],
    );

    const createFacture = useCallback(
        async (facture: FactureAchatCreate) => {
            const requestParameters: FactureAchatApiCreateFactureAchatFactureAchatCollectionRequest =
                {
                    factureAchatJsonldFactureAchatCreateFactureAchatWrite: facture,
                    xIdSociete,
                };

            return await factureAchatApi
                .createFactureAchatFactureAchatCollection(requestParameters)
                .then(
                    async (res) => {
                        if (res.status < 200 && res.status >= 300) {
                            enqueueSnackbar(
                                <Typography>
                                    Envoi de la facture raté ! (${res.status}) <br />
                                    {waitOrSupport}
                                </Typography>,
                                { variant: 'error' },
                            );
                            throw new Error(`
                        Erreur de communication avec l'API (${res.status})...
                        ${waitOrSupport}
                    `);
                        }

                        const factureCreated = res.data;

                        if (!factureCreated.id) {
                            enqueueSnackbar(
                                <Typography>
                                    Cette facture n&apos;a pas d&apos;identifiant ! <br />
                                    {waitOrSupport}
                                </Typography>,
                                { variant: 'error' },
                            );
                            throw new Error(`
                        Information manquante dans l'enregistrement...
                        ${waitOrSupport}
                    `);
                        }

                        const resSync = await synchroG3Facture(factureCreated.id.toString());

                        if (!resSync) {
                            enqueueSnackbar(
                                <Typography>La synchronisation G3 a échouée !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                        }

                        enqueueSnackbar(
                            <Typography>Envoi de la facture avec succès ! 🚀</Typography>,
                        );
                        return res;
                    },
                    (err) => {
                        let message: string = '';

                        if (typeof err === 'string') {
                            message = err;
                        } else if (Axios.isAxiosError(err)) {
                            if (err.response?.data) {
                                const axiosErrorResponse = err.response.data;
                                console.error(axiosErrorResponse);

                                if (axiosErrorResponse.exception.violations) {
                                    const propertyRegex = /(?<=\[|\.)\w+(?=\])|\w+(?=$|\[|\.)+/g;

                                    message = axiosErrorResponse.exception.violations.reduce(
                                        (
                                            acc: string,

                                            {
                                                messageToReduce,
                                                property,
                                            }: { messageToReduce?: string; property?: string },
                                        ) => {
                                            let res = acc;
                                            if (property && messageToReduce) {
                                                const regexResult = property.match(propertyRegex);
                                                if (regexResult instanceof Array) {
                                                    let prop = regexResult[regexResult.length - 1];
                                                    const productdline =
                                                        property.match(/lignes\[(\d+)\]/);
                                                    if (productdline instanceof Array) {
                                                        prop = `Produit ${Number(productdline[1]) + 1}`;
                                                    }
                                                    res += `${prop}: ${tradErrors(messageToReduce)}\n`;
                                                }
                                            } else if (messageToReduce) {
                                                res += messageToReduce;
                                            } else {
                                                res += 'erreur non traitée \n';
                                            }

                                            return res;
                                        },
                                        '',
                                    );
                                } else if (axiosErrorResponse.exception.message) {
                                    const axiosErrorResponseMessage: string =
                                        axiosErrorResponse.exception.message;
                                    if (axiosErrorResponseMessage.includes('ODBC Driver')) {
                                        message = "Impossible d'échanger avec la base de données.";
                                        console.error(axiosErrorResponseMessage);
                                    } else if (
                                        axiosErrorResponseMessage.includes(
                                            'Call to a member function',
                                        )
                                    ) {
                                        message =
                                            'Erreur technique lors de la verification des règles.';
                                        console.error(axiosErrorResponseMessage);
                                    } else {
                                        message = axiosErrorResponseMessage;
                                    }
                                } else {
                                    message = axiosErrorResponse.error;
                                }
                            }
                        } else if (err.exception) {
                            message = err.exception.message;
                        }

                        enqueueSnackbar(
                            <Typography>
                                Envoi de la facture raté ! 💥
                                <br />
                                {message}
                            </Typography>,
                            { variant: 'error' },
                        );
                        throw new Error(`
                Envoi de la facture raté ! (${message})...
                ${waitOrSupport}
            `);
                    },
                );
        },
        [enqueueSnackbar, synchroG3Facture, xIdSociete],
    );

    const onSubmitFacture: (facture: FactureAchatCreate, goToNext: boolean) => void = useCallback(
        async (facture, goToNext) => {
            try {
                const factureToCreate = { ...facture };
                if (mediaObject) {
                    factureToCreate.pdfFacture = mediaObject['@id'];
                }

                await createFacture(factureToCreate);

                await dispatch(getMediaObject({}));
                await dispatch(getMediaObjectByViewName('user_unregistered')({}));

                if (goToNext) {
                    return navigateToNewFacture(
                        showMessage,
                        dispatch,
                        navigate,
                        navigateToListe,
                        factureToCreate.pdfFacture ? factureToCreate.pdfFacture : undefined,
                        factureToCreate.idReleveAchat ? factureToCreate.idReleveAchat : undefined,
                    );
                }

                // En cas de relevé
                if (!goToNext && factureToCreate.idReleveAchat) {
                    return navigateToRecapStatement(navigate, factureToCreate.idReleveAchat);
                }
            } catch (error) {
                // TODO ajouter toast
                console.error(error);
            }
            return navigateToListe();
        },
        [createFacture, dispatch, mediaObject, navigate, navigateToListe, showMessage],
    );

    // TODO: L'action d'annulation ne doit pas ramener un utilisateur mada vers la liste, mais plutôt vers la prochaine saisie en ne soumettant pas la saisie courante ? A confirmer.
    const onCancel = useBackFromMediaObjectEdit();

    const loadData = useCallback(
        () => !!(mediaObject && mediaObjectDataSource.selectedStatus !== 'loading'),
        [mediaObject, mediaObjectDataSource.selectedStatus],
    );

    const { loading } = useLoadingStatus({ checkReady: loadData });

    const form = useMemo(
        () => (
            <RegisterSimpleInvoiceForm
                mediaObject={mediaObject as MediaObject}
                key={mediaObject?.contentUrl}
                facture={newFacture}
                onSubmit={(facture, goToNext) => {
                    onSubmitFacture(facture, goToNext || false);
                }}
                onCancel={() => {
                    onCancel();
                }}
            />
        ),
        [mediaObject, newFacture, onCancel, onSubmitFacture],
    );

    return (
        <SkeletonLoader
            isLoading={loading}
            type="SplitView"
        >
            <Grid container>
                <Grid
                    item
                    lg={6}
                    sm={12}
                >
                    <MediaObjectReader
                        mediaObject={mediaObject}
                        sx={{
                            height: `calc(100vh - ${footerHeight}px - ${TopbarHeight}px  )`,
                        }}
                    />
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={12}
                >
                    <Card>{form}</Card>
                </Grid>
            </Grid>
        </SkeletonLoader>
    );
};

export default RegisterSimpleInvoiceView;
