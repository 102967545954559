/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement API Utilisateur
 * API de gestion des utilisateurs
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiUtilisateursPrescripteurIdGetCollection200Response
 */
export interface ApiUtilisateursPrescripteurIdGetCollection200Response {
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:member': any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:totalItems'?: any;
    /**
     * 
     * @type {ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:view'?: ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200Response
     */
    'hydra:search'?: ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch
 */
export interface ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: any;
}
/**
 * 
 * @export
 * @interface ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
 */
export interface ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView {
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
     */
    'hydra:first'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
     */
    'hydra:last'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
     */
    'hydra:previous'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiUtilisateursPrescripteurIdGetCollection200ResponseHydraView
     */
    'hydra:next'?: any;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'username'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'emailVerified'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof User
     */
    'idPrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface UserCreateUser
 */
export interface UserCreateUser {
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'username': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'enabled': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'emailVerified': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUser
     */
    'idPrescripteur': any;
}
/**
 * 
 * @export
 * @interface UserCreateUserJsonld
 */
export interface UserCreateUserJsonld {
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'username': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'enabled': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'emailVerified': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof UserCreateUserJsonld
     */
    'idPrescripteur': any;
}
/**
 * 
 * @export
 * @interface UserJsonld
 */
export interface UserJsonld {
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    '@context'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    '@id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    '@type'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'id'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'username'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'emailVerified'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserJsonld
     */
    'idPrescripteur'?: any;
}
/**
 * 
 * @export
 * @interface UserUpdateUser
 */
export interface UserUpdateUser {
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'enabled'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'emailVerified'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'firstName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'lastName'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'email'?: any;
    /**
     * 
     * @type {any}
     * @memberof UserUpdateUser
     */
    'idPrescripteur'?: any;
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {any} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurIdPatch: async (id: any, userUpdateUser: UserUpdateUser, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUtilisateurIdPatch', 'id', id)
            // verify required parameter 'userUpdateUser' is not null or undefined
            assertParamExists('apiUtilisateurIdPatch', 'userUpdateUser', userUpdateUser)
            const localVarPath = `/utilisateur/utilisateur/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurPost: async (userCreateUserJsonld: UserCreateUserJsonld, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreateUserJsonld' is not null or undefined
            assertParamExists('apiUtilisateurPost', 'userCreateUserJsonld', userCreateUserJsonld)
            const localVarPath = `/utilisateur/utilisateur`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateUserJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {any} prescripteurId User identifier
         * @param {any} [page] The collection page number
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursPrescripteurIdGetCollection: async (prescripteurId: any, page?: any, xIdSociete?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescripteurId' is not null or undefined
            assertParamExists('apiUtilisateursPrescripteurIdGetCollection', 'prescripteurId', prescripteurId)
            const localVarPath = `/utilisateur/utilisateurs/{prescripteurId}`
                .replace(`{${"prescripteurId"}}`, encodeURIComponent(String(prescripteurId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {any} id User identifier
         * @param {UserUpdateUser} userUpdateUser The updated User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateurIdPatch(id: any, userUpdateUser: UserUpdateUser, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateurIdPatch(id, userUpdateUser, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserCreateUserJsonld} userCreateUserJsonld The new User resource
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateurPost(userCreateUserJsonld: UserCreateUserJsonld, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJsonld>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateurPost(userCreateUserJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {any} prescripteurId User identifier
         * @param {any} [page] The collection page number
         * @param {any} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUtilisateursPrescripteurIdGetCollection(prescripteurId: any, page?: any, xIdSociete?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUtilisateursPrescripteurIdGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUtilisateursPrescripteurIdGetCollection(prescripteurId, page, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Updates the User resource.
         * @summary Updates the User resource.
         * @param {UserApiApiUtilisateurIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurIdPatch(requestParameters: UserApiApiUtilisateurIdPatchRequest, options?: AxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {UserApiApiUtilisateurPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateurPost(requestParameters: UserApiApiUtilisateurPostRequest, options?: AxiosRequestConfig): AxiosPromise<UserJsonld> {
            return localVarFp.apiUtilisateurPost(requestParameters.userCreateUserJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {UserApiApiUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiUtilisateursPrescripteurIdGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiUtilisateursPrescripteurIdGetCollection200Response> {
            return localVarFp.apiUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiUtilisateurIdPatch operation in UserApi.
 * @export
 * @interface UserApiApiUtilisateurIdPatchRequest
 */
export interface UserApiApiUtilisateurIdPatchRequest {
    /**
     * User identifier
     * @type {any}
     * @memberof UserApiApiUtilisateurIdPatch
     */
    readonly id: any

    /**
     * The updated User resource
     * @type {UserUpdateUser}
     * @memberof UserApiApiUtilisateurIdPatch
     */
    readonly userUpdateUser: UserUpdateUser

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UserApiApiUtilisateurIdPatch
     */
    readonly xIdSociete?: any
}

/**
 * Request parameters for apiUtilisateurPost operation in UserApi.
 * @export
 * @interface UserApiApiUtilisateurPostRequest
 */
export interface UserApiApiUtilisateurPostRequest {
    /**
     * The new User resource
     * @type {UserCreateUserJsonld}
     * @memberof UserApiApiUtilisateurPost
     */
    readonly userCreateUserJsonld: UserCreateUserJsonld

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UserApiApiUtilisateurPost
     */
    readonly xIdSociete?: any
}

/**
 * Request parameters for apiUtilisateursPrescripteurIdGetCollection operation in UserApi.
 * @export
 * @interface UserApiApiUtilisateursPrescripteurIdGetCollectionRequest
 */
export interface UserApiApiUtilisateursPrescripteurIdGetCollectionRequest {
    /**
     * User identifier
     * @type {any}
     * @memberof UserApiApiUtilisateursPrescripteurIdGetCollection
     */
    readonly prescripteurId: any

    /**
     * The collection page number
     * @type {any}
     * @memberof UserApiApiUtilisateursPrescripteurIdGetCollection
     */
    readonly page?: any

    /**
     * Identifiant de la société
     * @type {any}
     * @memberof UserApiApiUtilisateursPrescripteurIdGetCollection
     */
    readonly xIdSociete?: any
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Updates the User resource.
     * @summary Updates the User resource.
     * @param {UserApiApiUtilisateurIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUtilisateurIdPatch(requestParameters: UserApiApiUtilisateurIdPatchRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUtilisateurIdPatch(requestParameters.id, requestParameters.userUpdateUser, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {UserApiApiUtilisateurPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUtilisateurPost(requestParameters: UserApiApiUtilisateurPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUtilisateurPost(requestParameters.userCreateUserJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {UserApiApiUtilisateursPrescripteurIdGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUtilisateursPrescripteurIdGetCollection(requestParameters: UserApiApiUtilisateursPrescripteurIdGetCollectionRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUtilisateursPrescripteurIdGetCollection(requestParameters.prescripteurId, requestParameters.page, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


