import useApiRequest from '@b2d/hooks/useApiRequest';
import { utilisateurApi } from '@b2d/redux/RootStore';
import { Contact, Prescripteur } from '@europrocurement/l2d-domain';
import { User, UserCreateUserJsonld } from '@europrocurement/l2d-domain/openApi/ApiUtilisateur';
import { useState } from 'react';

export const usePrescriberContacts = () => {
    const { request } = useApiRequest();
    const [users, setUsers] = useState<User[]>([]);

    const getUsers = (prescriberId: number, xIdSociete: number) => {
        request(
            utilisateurApi.apiUtilisateursPrescripteurIdGetCollection({
                prescripteurId: prescriberId,
                xIdSociete,
            }),
            {
                withToaster: false,
                successCallback: (response) => {
                    setUsers(response.data['hydra:member'] || []);
                },
            },
        );
    };

    const contactHasUser = (contact: Contact, prescriberUsers: User[]): string | null => {
        const availableEmails = prescriberUsers.map((user) => user.email);

        for (let i = 0; i < contact.emailsContact.length; i++) {
            if (availableEmails.indexOf(contact.emailsContact[i].email) !== -1) {
                return contact.emailsContact[i].email;
            }
        }

        return null;
    };

    const createUser = (payload: UserCreateUserJsonld): Promise<void> =>
        request(utilisateurApi.apiUtilisateurPost({ userCreateUserJsonld: payload }), {
            successCallback: () => {},
        });

    const hasB2bAccess = (prescriber: Prescripteur, xIdSociete: number): boolean => {
        const t = prescriber.relationsSocietes.filter(
            (relation) =>
                relation?.societe?.id === xIdSociete && relation.plateformeB2BAutorise === true,
        );

        return t.length > 0;
    };

    return { users, contactHasUser, getUsers, createUser, hasB2bAccess };
};
